@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
	&::-webkit-scrollbar,
	&::-webkit-scrollbar-track {
		@apply bg-pink-500 dark:bg-dark-500;
	}

	&::-webkit-scrollbar-thumb {
		@apply rounded-l-md bg-dark-500 dark:bg-pink-500;
	}
}
